// @import "./antd.less";
@import "./var";
@import "./common";
@import "./icon";
@import "../../../commonStyle/reset";
@import "./animation";
@import "./xh";
@import "../../../commonStyle/common";


.my_reset {
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 0;
  }
}


html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  //line-height:0;
  //overflow: auto;
}


body, html {
  height: 100%;
  min-height: 100%;
  min-width: 1180px;
}



