/* Animation css */
[class*=animation-] {
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: both
}
.animation-fade {
    animation-name: fade;
    animation-duration: .6s;
    animation-timing-function: linear;
}
.animation-move-right{
    animation-name: moveRight;
    animation-duration: .6s;
    animation-timing-function: linear;
}
.animation-banner-scale{
    animation-name: bannerScale;
    animation-duration: 6s;
    animation-timing-function: linear;
}
.animation-icon-down{
    animation-name: toDown;
    animation-duration: .6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.animation-icon-top{
    animation-name: toTop;
    animation-duration: .6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.animation-loading{
    animation: loading 1s linear infinite;
}
@keyframes toDown {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(6px);
    } 
}
@keyframes toTop {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-6px);
    } 
}
@keyframes fade {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}
@keyframes bannerScale {
    0% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.2);
    }
}
@keyframes moveRight {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(10px);
    }
}
@keyframes loading{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}