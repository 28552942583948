@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-email {
  .iconGen(email, 16px, 16px);
}
.icon-phone {
  .iconGen(phone, 17px, 13px);
}
.icon-address {
  .iconGen(address, 13px, 15px);
}
.icon-close-image {
  .iconGen(mfp-close, 56px, 56px);
}
.icon-calendar {
  .iconGen(calendar, 16px, 16px);
}
.icon-close {
  .icon-close-image;
}
.icon-add-bold {
  .iconGen(plus, 16px, 16px);
}
.icon-minus-bold {
  .iconGen(reduce, 16px, 16px);
}